import axios from "./axios"
import axios_json_python_dvc from "./axios/axios_json_python_dvc"

export function OCRPassport(data) {
  return axios.post("/mindee", data)
}

export function SendPassport(data) {
  return axios.post("/passport/create", data)
}

export function GetSentList(data) {
  return axios.get("/passport/sent/list", {
    params: data
  })
}

export function GetChinaDataList(data) {
  return axios.get("/china/list", {
    params: data
  })
}

export function RedownImage(data) {
  return axios.post("/china/redown", data)
}

export function ListBCAResult(data) {
  return axios.get("/dichvucong/list", {
    params: data
  })
}

export function GetUserFee(type) {
  return axios.get(`/user/doitac/fee?type=${type}`)
}

export function GetNhanvienByDoitac() {
  return axios.get("/user/doitac/nhanvien/list")
}

export function CreateNhanVien(data) {
  return axios.post("/user/doitac/create/nhanvien", data)
}

export function GetRandomAddressEvisa() {
  return axios.get("/evisa/address/get_random")
}

export function GetRandomFakeInfoEvisa(quoc_tich) {
  return axios.get("/evisa/fakeinfo/get?quoc_tich=" + quoc_tich)
}

export function SendEvisaApi(data) {
  return axios.post("/evisa/queue/create_with_fee", data)
}

export function SendEvisaChinaApi(data) {
  return axios.post("/evisa/queue/china/create_with_fee", data)
}

export function GetListEvisaChina() {
  return axios.post("/evisa/china/list")
}

export function RedownImageEvisa(data) {
  return axios.post("/evisa/china/image/redown", data)
}

export function GetPassportByMaHoSo(ma_ho_so) {
  return axios.get(`/passport/mahoso/${ma_ho_so}`)
}

export function GetPassportSubmittedBCA(data) {
  return axios.get("/passport/sent/list/submitted", {
    params: data
  })
}

export function DichVuCongCheckMahoso(values) {
  let data = JSON.stringify(values)
  return axios_json_python_dvc.post("check", data)
}

export function CreateNewResultBca(data) {
  return axios.post("/dichvucong/result/manual/create", data)
}

export function GetListEvisaSent(data) {
  return axios.get("/evisa_a05/orders/list", {
    params: data
  })
}

export function GetUserInfo() {
  return axios.get("/user/doitac/info")
}

export function GetChinaOption() {
  return axios.get("/china/option")
}

export function UpdateChinaData(data) {
  return axios.post("/china/update", data)
}

export function GetOneDVCHoSo(so_ho_so) {
  return axios.get(`/dichvucong/get?so_ho_so=${so_ho_so}`)
}

export function CancelDVCHoSo(data) {
  return axios.post("/dichvucong/cancel", data)
}

export function ManualFetchDVCHoSo(data) {
  return axios.post("/dichvucong/update/manual", data)
}

export function ListEvisaChinaUpdateEvisa(data) {
  return axios.post("/evisa/china/update", data)
}

export function ToolCheckEvisa(data) {
  return axios.post("/evisa/check", data)
}

export function RemovePassport(id) {
  return axios.get(`/passport/remove/${id}`)
}

export function SubmitNewPassportByExcel(data) {
  return axios.post(`/passport/create/excel`, data)
}

export function GetListUserLabel() {
  return axios.get(`/user/doitac/list/label`)
}

export function CheckPassportValidDoitac(data) {
  return axios.post(`passport/check/valid_by_doitac`, data)
}

export function GetEmailDomain() {
  return axios.get("evisa/mail/get_domain")
}
